import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const loaction = window.location.pathname;
  return (
    <div>
      <div className="headerContainer">
        <div className="leftF">
          <img src={require("./logo.png")} alt="logo" />
          <span className="title">源码标杆</span>
        </div>
        <div className="rightF">
          {/*<span>全国热线：400-800-1234</span>*/}
          <span>24小时提供电话咨询服务</span>
          <span>联系我们：152-8274-1516</span>
          <div className="menu">
            <div
              className={loaction === "/" ? "menuTitle active" : "menuTitle"}
              onClick={() => {
                navigate("/");
              }}
            >
              首页
            </div>
            <div
              className={
                loaction === "/software" ? "menuTitle active" : "menuTitle"
              }
              onClick={() => {
                navigate("/software");
              }}
            >
              软件定制
            </div>
            <div
              className={
                loaction === "/website" ? "menuTitle active" : "menuTitle"
              }
              onClick={() => {
                navigate("/website");
              }}
            >
              网站开发
            </div>
            <div
              className={
                loaction === "/appPage" ? "menuTitle active" : "menuTitle"
              }
              onClick={() => {
                navigate("/appPage");
              }}
            >
              APP开发
            </div>
            <div
              className={
                loaction === "/applet" ? "menuTitle active" : "menuTitle"
              }
              onClick={() => {
                navigate("/applet");
              }}
            >
              小程序开发
            </div>
            <div
              className={
                loaction === "/about" ? "menuTitle active" : "menuTitle"
              }
              onClick={() => {
                navigate("/about");
              }}
            >
              联系我们
            </div>
          </div>
        </div>
      </div>
      <div className="bannerBox">
        <img className="banner" src={require("./banner.png")} alt="" />
        <div className="contentTitle">
          <p>
            <span className="tag">“互联网+”时代</span>
            <span>把握机会</span>
          </p>
          <span>助您轻松跨入“互联网+”时代</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
