import React, { Component } from "react";
import "./index.scss";
import { CheckCircleFilled } from "@ant-design/icons";

export default class Home extends Component {
  render() {
    return (
      <div className="container">
        <div className="lf">
          <div className="moduleTitle">
            <h2>软件定制服务</h2>
            <span>更专注精研只为打造更优系统</span>
          </div>
          <div className="moduleContent">
            <div className="business">
              <img
                className="businessLogo"
                src={require("./customize.png")}
                alt=""
              />
              <span>后台系统定制</span>
            </div>
            <div className="business">
              <img
                className="businessLogo"
                src={require("./hot.png")}
                alt=""
              />
              <span>热门软件开发</span>
            </div>
            <div className="business">
              <img
                className="businessLogo"
                src={require("./web.png")}
                alt=""
              />
              <span>商业网站定制</span>
            </div>
            <div className="business">
              <img
                className="businessLogo"
                src={require("./deal.png")}
                alt=""
              />
              <span>行业解决方案</span>
            </div>
          </div>
        </div>
        <div className="mf">
          <div className="moduleTitle">
            <h2>APP定制</h2>
            <span>让您得企业更高效地完成工作</span>
          </div>
          <div className="moduleContent">
            <div>
              <CheckCircleFilled
                style={{ fontSize: "30px", color: "#f26e7f" }}
              />
              <div className="moduleBox top">
                <h3>针对性强</h3>
                <span>
                  每一个软件开发都经过系统的分析，针对不同企业情况量身定做的，大大提高企业的工作效率。
                </span>
              </div>
            </div>
            <div>
              <CheckCircleFilled
                style={{ fontSize: "30px", color: "#fdae60" }}
              />
              <div className="moduleBox middle">
                <h3>使用方便</h3>
                <span>
                  根据企业现有工作流程开发程序，用户只需使用手机就可以自如的操作软件。
                </span>
              </div>
            </div>
            <div>
              <CheckCircleFilled
                style={{ fontSize: "30px", color: "#fdd360" }}
              />
              <div className="moduleBox bottom">
                <h3>服务周到</h3>
                <span>
                  定制软件在使用过程中出现的问题，我们会及时沟通调试为你提供专业、迅捷的服务。
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="rf">
          <div className="moduleTitle">
            <h2>小程序定制服务</h2>
            <span>专业技术团队，打造小程序开发品牌</span>
          </div>
          <div className="moduleContent">
            <div className="moduleBox">
              <img
                className="businessLogo"
                src={require("./wx.png")}
                alt=""
              />
              <div>
                <h3>微信小程序</h3>
                <span>
                  微信小程序是微信生态体系的优先选择，微信倾力打造的小程序体系，将是微信生态的未来主旋律。从现在抓住风口。
                </span>
              </div>
            </div>
            <div className="moduleBox">
              <img
                className="businessLogo"
                src={require("./bd.png")}
                alt=""
              />
              <div>
                <h3>百度小程序</h3>
                <span>
                  人与万物的开放生态，依托全域流量，通过百度AI开放式赋能，精准连接用户，无需下载安装便可享受指挥超前的使用体验。
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
