import React, { Component } from "react";
import Home from "./home";
import AppPage from "./appPage";
import Applet from "./applet";
import Website from "./website";
import Software from "./software";
import About from "./about";
import { Routes, Route } from "react-router-dom";

export default class Mian extends Component {
  render() {
    return (
      <div>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/appPage" element={<AppPage />}></Route>
          <Route path="/applet" element={<Applet />}></Route>
          <Route path="/website" element={<Website />}></Route>
          <Route path="/software" element={<Software />}></Route>
          <Route path="/about" element={<About />}></Route>
        </Routes>
      </div>
    );
  }
}
