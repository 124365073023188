import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const loaction = window.location.pathname;
  return (
    <div className="footerContainer">
      <div className="menuList">
        <div className="menu">
          <div
            className={loaction === "/" ? "menuTitle active" : "menuTitle"}
            onClick={() => {
              navigate("/");
            }}
          >
            首页
          </div>
            <div
                className={
                    loaction === "/software" ? "menuTitle active" : "menuTitle"
                }
                onClick={() => {
                    navigate("/software");
                }}
            >
                软件定制
            </div>
            <div
                className={
                    loaction === "/website" ? "menuTitle active" : "menuTitle"
                }
                onClick={() => {
                    navigate("/website");
                }}
            >
                网站开发
            </div>
          <div
            className={
              loaction === "/appPage" ? "menuTitle active" : "menuTitle"
            }
            onClick={() => {
              navigate("/appPage");
            }}
          >
            APP开发
          </div>
          <div
            className={
              loaction === "/applet" ? "menuTitle active" : "menuTitle"
            }
            onClick={() => {
              navigate("/applet");
            }}
          >
            小程序开发
          </div>
          <div
            className={loaction === "/about" ? "menuTitle active" : "menuTitle"}
            onClick={() => {
              navigate("/about");
            }}
          >
            联系我们
          </div>
        </div>
      </div>
      <div className="footerContent">
        <img src={require("./logo.png")} alt="" />
        <div className="copyright ll">
          <div>
            <span>  源码标杆 @2022版权所有</span>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noreferrer noopener">蜀备字： 蜀ICP备2022009905号</a>
            <span></span>
          </div>
          <div>
            Copyright © 2022 源码标杆(成都)信息技术有限公司 All Rights Reserved.
          </div>
        </div>
        <div className="copyright rr">
          <div>咨询热线：</div>
          <div>152-8274-1516 微信同号</div>
          <div>183-8133-0991 微信同号</div>
        </div>
        <img className="wechat" width='82' height='82' src={require("./wechat1.jpg")} alt="" />
        <img className="wechat" width='82' height='82' src={require("./wechat2.jpg")} alt="" />
      </div>
    </div>
  );
};

export default Footer;
