import React from "react";
import "./index.scss";

const Website = () => {
  return <div className="websiteContainer">
    <img src={require('./website.png')} alt="" />
  </div>;
};

export default Website;
