import React from "react";
import "./index.scss";
import { Input, Form, Button } from "antd";
const { TextArea } = Input;

const About = () => {
  return (
    <div className="aboutContainer">
      <div>
        <h2 className="moduleTitle">企业简介</h2>
        <span>
          源码标杆互联网信息技术服务提供商和大数据服务提供商；具备研发数字内容、图形识别管理、传统行业电商云化和大数据相关的核心技术；
          致力于服务行业信息、互联网技术服务
          承接公司、集团、政府信息化、互联网各类云服务平台的建设、运维以及大数据服务。
          始终以自主创新为核心驱动力，紧扣时代脉搏，海量数字数据全生命周期的管理，推出引领行业发展的技术和服务。
          截至目前，公司已开发积累了涵盖数字内容分析、数据存储和数据应用全生命周期管理的六大核心技术，达到国际国内行业质量标准。
          客户涵盖电商业、房产业、医药业、教育业、服装业。
        </span>
      </div>
      <div>
        <h2 className="moduleTitle">企业文化</h2>
        <div className="moduleContent">
          <div>
            <p>
              以终为始：做事之前，先定目标；根据目标不断矫正行为、不断试错；强烈的完成使命感与方向感
            </p>
            <p>
              追求卓越：“做了”与“做好了”是反义词，要做就要做到最好，要做就要有成果，要做就要100分！
            </p>
            <p>
              抓大放小：抓住量级提升的机会，抓住关键点、挑选投入产出比最高的事情优先做
            </p>
          </div>
          <div>
            <p>
              心态积极：积极就是生产力，始终心态阳光，主动积极地看待问题、解决问题
            </p>
            <p>
              英雄主义：强烈的成就欲；有想法，能够直接表达；有能力，能够挺身而出
            </p>
            <p>
              终身学习：不断学习的意识和能力，不断拥抱变化，在快速变化的环境中脱颖而出
            </p>
          </div>
        </div>
      </div>
      <div>
        <h2 className="moduleTitle">联系我们</h2>
        <div className="contact">
          <div>
            <span>联系QQ: 649055408</span>
            <span>手机号码: 15282741516</span>
            <span>联系邮箱: cai649055408@live.com</span>
            {/*<span>联系电话: 020-000000</span>*/}
            <span>联系地址: 四川省成都市高新区雅和南一路99号3栋3102</span>
            <span>或者您可以在右边提交信息，我们会安排专员联系您</span>

          </div>
          <div>
            <Form name="basic" autoComplete="off" initialValues={{}}>
              <Form.Item
                label="联系人"
                rules={[{ required: true, message: "请输入姓名 !" }]}
              >
                <Input placeholder="请输入姓名"></Input>
              </Form.Item>
              <Form.Item
                label="联系电话"
                rules={[{ required: true, message: "请输入电话!" }]}
              >
                <Input placeholder="请输入电话"></Input>
              </Form.Item>
              <Form.Item label="合作简述">
                <TextArea rows={4} placeholder="请输入您的合作意向"></TextArea>
              </Form.Item>
              <Form.Item>
                <Button type="primary" block>
                  免费获取报价方案
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
