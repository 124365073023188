import Main from "./pages/main";
import Header from "./components/header";
import Footer from "./components/footer";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Header></Header>
        <Main></Main>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
