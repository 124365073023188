import React from "react";
import "./index.scss";

const Software = () => {
  return (
    <div className="softContainer">
      <img src={require("./software.png")} alt="" />
      <div className="softContent">
        <div>
          <h3>按需定制开发</h3>
          <span>
            经验丰富的工程师针对您的业务需求情况 为您解决业务、需求相关难题。
          </span>
        </div>
        <div>
          <h3>大数据仓库</h3>
          <span>
            为您提供包括从数据采集、数据存储、数据加工、数据管理
            数据运维调度等完整的数仓解决方案。
          </span>
        </div>
        <div>
          <h3>云上数据集成方案</h3>
          <span>
            提供可跨异构数据存储系统、可靠、安全、低成本
            可弹性扩展的数据传输交互服务 让您的数据不再成为孤岛！
          </span>
        </div>
      </div>
    </div>
  );
};

export default Software;
