import React, { Component } from "react";
import "./index.scss";
import { CheckCircleFilled } from "@ant-design/icons";

export default class AppPage extends Component {
  render() {
    return (
      <div className="container">
        <img src={require("./app.png")} alt="" />
        <div className="rfModule">
          <div className="moduleTitle">
            <h2>APP定制优势</h2>
            <span>让您得企业更高效地完成工作</span>
          </div>
          <div className="moduleContent">
            <div>
              <CheckCircleFilled
                style={{ fontSize: "30px", color: "#f26e7f" }}
              />
              <div className="moduleBox top">
                <h3>针对性强</h3>
                <span>
                  每一个软件开发都经过系统的分析，针对不同企业情况量身定做的，大大提高企业的工作效率。
                </span>
              </div>
            </div>
            <div>
              <CheckCircleFilled
                style={{ fontSize: "30px", color: "#fdae60" }}
              />
              <div className="moduleBox middle">
                <h3>使用方便</h3>
                <span>
                  根据企业现有工作流程开发程序，用户只需使用手机就可以自如的操作软件。
                </span>
              </div>
            </div>
            <div>
              <CheckCircleFilled
                style={{ fontSize: "30px", color: "#fdd360" }}
              />
              <div className="moduleBox bottom">
                <h3>服务周到</h3>
                <span>
                  定制软件在使用过程中出现的问题，我们会及时沟通调试为你提供专业、迅捷的服务。
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
