import React from "react";
import "./index.scss";

const Applet = () => {
  return (
    <div className="appletConatiner">
      <img src={require("./applet.png")} alt="" />
        <div className="appletContent">
            <div>
                <h3>企业营销新利器</h3>
                <span>
             小程序助力商家破局增长,是吸引新用户、维护老客户的新大陆。还可以帮助企业开发新的商业模式。
          </span>
            </div>
            <div>
                <h3>提升品牌知名度</h3>
                <span>
            小程序可以帮助企业提升品牌知名度，并且可以通过分享功能让更多的人了解和使用你的小程序。
          </span>
            </div>
            <div>
                <h3>精准控制成本</h3>
                <span>
            自带流量入口大，但成本更低，轻量化，体积小，功能齐全，微信内即可直达。
          </span>
            </div>
        </div>
      {/*<div className="contentText">*/}
      {/*  即开即用 无需下载安装便可以享受体验 开发更便捷，时间更短投入也更少*/}
      {/*  给企业带来品牌的传播，销量的提升。*/}
      {/*</div>*/}
    </div>
  );
};

export default Applet;
